<template>
  <div style="margin: 10px;">
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <work-subject :p_showDetails="{'type': 'selected', 'subject_name': 'treatmentcycleincreasepage'}"></work-subject>
        <hr>
        <h3>Komponent Kullanımı</h3>
        <h6>Konsolu f12 ile açarak tetiklemelerin console.log larını izleyebilirsiniz.</h6>
        <hr>
        <treatment-cycle-increase :p_treatmentDrugCycle="d_treatmentDrugCycle"></treatment-cycle-increase>
        <hr>
        <h3>Komponent açılan modalde görünüm</h3>
        <h6>Butona tıklayarak açılan modalde tarih değiştirme komponentini görüntüleyebilirsiniz</h6>
        <b-row>
          <b-col sm="12" lg="12">
            <b-button @click="f_openTreatmentDrugCycleIncreaseModal()" variant="primary">Siklus artırma ekranı</b-button>
          </b-col>
        </b-row>
        <hr>
        <template v-for="(code_example, code_example_index)  in d_componentExample.schema_drugs">
          <h3> {{ code_example.name }} </h3>
          <div class="code-text">{{ code_example.code }}</div>
        </template>
        <hr>
        <sql-database :p_tableShowDetails="{'type': 'selected', 'table_name': 'PatientTreatmentDrugs'}"></sql-database>
        <hr>
        <sql-database :p_tableShowDetails="{'type': 'selected', 'table_name': 'PatientTreatmentDrugsApplication'}"></sql-database>
        <hr>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
    <b-modal v-if="d_treatmentDrugCycleIncrease.show" v-model="d_treatmentDrugCycleIncrease.show" centered class="modal-success" @ok="d_treatmentDrugCycleIncrease.show = false" ok-variant="success" hide-footer size="md">
      <b-row>
        <b-col cols="12">
          <treatment-cycle-increase :p_treatmentDrugCycle="d_treatmentDrugCycle"></treatment-cycle-increase>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <b-button variant="danger" @click="d_treatmentDrugCycleIncrease.show = false">Kapat</b-button>
          <b-button variant="success" @click="f_applyDrugCycleIncrease()">Siklus ekleme işlemini Uygula</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  default as WorkSubject
} from '@/components/widgets/WorkSubject';
import {
  default as TreatmentCycleIncrease
} from '@/components/widgets/TreatmentCycleIncrease';
import {
  default as SqlDatabase
} from '@/components/widgets/SqlDatabase';
import { mapGetters } from 'vuex';
export default {
  name: 'TreatmentCycleIncreasePage',
  computed: mapGetters({
    // lang: 'lang'
  }),
  components: {
    WorkSubject,
    TreatmentCycleIncrease,
    SqlDatabase,
  },
  props: {},
  data () {
    return {
      d_treatmentDrugCycle: {
        'cycle_count': 1,
        'drugs': [{
          'selected': true,
          'DrugId': 1,
          'DrugName': 'Paklitaksel',
          'planned_dosage': 120,
          'day_list': [1],
          'last_cycle': 2,
          'period': 21
        }]
      },
      d_treatmentDrugCycleIncrease: {
        'show': false,
        'new': '',
        'offset': 0,
        'reason': []
      },
      d_treatmentList: require('@/example_data/treatment_list').example_data,
      d_patientData: require('@/example_data/patient_data').example_data,
      d_treatmentData: require('@/example_data/treatment_data').example_data,
      d_componentExample: {
        'schema_drugs': [
          { 'code': '<treatment-cycle-increase :p_treatmentDrugCycle="d_treatmentDrugCycle"></treatment-cycle-increase>', 'name': 'Örnek komponent kullanımı' },
        ]
      },
    };
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_applyDrugCycleIncrease: function () {
      console.log('f_applyDrugCycleIncrease ...');
    },
    f_openTreatmentDrugCycleIncreaseModal: function () {
      this.d_treatmentDrugCycleIncrease = {
        'show': true,
        'new': '',
        'offset': 0,
        'reason': []
      };
    }
  },
  watch: {}
}

</script>

<style type="text/css">


</style>

