<template>
  <div>
    <b-row>
      <b-col sm="12" lg="6">
        <strong>Eklenecek Siklus Adedi</strong>
      </b-col>
      <b-col sm="12" lg="6">
        <input type="number" class="form-control" min="0" name="cycle_count" v-model="p_treatmentDrugCycle.cycle_count">
      </b-col>
    </b-row>
    <table border="1" align="center" style="width: 100%; table-layout: fixed; overflow-wrap: break-word;">
      <thead>
        <tr>
          <th>işlem</th>
          <th>ilaç</th>
          <th>period</th>
          <th>günler</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(treatment_drug, treatment_drug_index) in p_treatmentDrugCycle.drugs">
          <tr>
            <td>
              <b-form-checkbox v-model="treatment_drug.selected"></b-form-checkbox>
            </td>
            <td> {{ treatment_drug.DrugName }} {{ treatment_drug.planned_dosage }} </td>
            <td>
              <input type="number" class="form-control" min="0" name="offset" v-model="treatment_drug.period">
            </td>
            <td> {{ treatment_drug.day_list }} </td>
          </tr>
        </template>
      </tbody>
    </table>
    <b-row>
      <b-col sm="12" lg="12">
        <small style="color: green;"> Not: Seçili ilaçlara, belirlediğiniz peryod ve günlerde son siklustaki dozları dikkate alınarak belirlediğiniz siklus sayısınca ekleme yapılmaktadır. </small>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
  name: 'TreatmentCycleIncrease',
  computed: mapGetters({
    // lang: 'lang'
  }),
  components: {},
  props: {
    p_treatmentDrugCycle: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      d_dateChangeReasonList: require('@/tables/DateChangeReason').options,
    };
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_dateFormat: function (date) {
      let formatted_date = '';
      if (date) {
        formatted_date = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY");
      }
      return formatted_date;
    },
  },
  watch: {}
}

</script>

<style type="text/css">


</style>

